<!--
 功能名称:NavBar 导航栏
 开发人:梁高权
 创建时间:2021/03/13
 最后修改时间:2021/03/13
-->
<template>
  <van-nav-bar
    :title="title?title:$route.meta.title"
    left-text="返回"
    left-arrow
    z-index="2"
    :fixed="true"
    :placeholder="true"
    @click-left="onClickLeft"
  />
</template>

<script>
export default {
  name: 'NavBar',
  props:['title'],
  methods: {
    onClickLeft() {
      this.$router.go(-1);//返回上一层
    }
  }
}
</script>
<style lang="scss" scoped>
  /deep/ .van-nav-bar__left{
    i{color: #2C6BF8}
    span{color: #2C6BF8}
  }
</style>
