<!--
 功能名称:千里眼平台(短信)-群发短信
 开发人:梁高权
 创建时间:2021/05/21
 最后修改时间:2021/05/21
-->

<template>
  <div class="groupSMS-wrap">
    <NavBar />
    <div class="content">
      <h1>
        <div>
          短信剩余条数：
          <span>{{note.sms_num}}条</span>
        </div>
        <van-button round size="mini" color="#FFEFC5" @click="callPhone('073182297060')">我要充值短信</van-button>
      </h1>
      <div class="line1">
        <h2>发送群体</h2>
        <div>
          当前已选会员
          <span>{{member_num}}人</span>
        </div>
      </div>

      <van-field v-if="$route.query.id" v-model="group_name" maxlength="10" readonly  placeholder="请为筛选群体设置名称，方便下次使用" />
      <van-field v-else v-model="group_name" maxlength="10" placeholder="请为筛选群体设置名称，方便下次使用" />
      <div class="line2">
        <h2>群发内容</h2>
        <van-field v-model="SMScontent.template" rows="3" autosize type="textarea" maxlength="280" placeholder="请输入短信类容" show-word-limit />
        <p>
          1、单条短信长度（即签名、短信内容和退订方式数字之和）不得超过280字。
          <br />
          2、超过70字，按60字/条，逐条计费。单个汉字、标点、英文都 算一个字、回车记两个字。
        </p>
      </div>
      <div class="line3">
        <div class="switch">
          <h2>定时发送</h2>
          <van-switch v-model="checked" size="20px" active-color="#09C89D" inactive-color="#dcdee0" />
        </div>
        <div v-if="checked">
          <van-field
            v-model="Time"
            @click="
              show = !show;
              currentDate = sendingTime;
            "
            readonly
            :right-icon="require('@/assets/images/calendar.png')"
          ></van-field>
          <p>你可以选择任意时刻定时群发，成功设置后不支持修改，但在设定的时间之前可取消，取消后不占用群发条数</p>
        </div>
      </div>
      <van-button round type="info" @click="send">群发短信</van-button>
      <van-popup v-model="show" position="bottom" :style="{ height: '51%' }">
        <van-datetime-picker v-model="currentDate" type="datehour" title="选择年月日小时" :min-date="minDate" @cancel="show = !show" @confirm="confirm" />
      </van-popup>
    </div>
  </div>
</template>

<script>
import table from '@/api/table';
import { mapState } from 'vuex';
import util from '@/utils/util.js';
import NavBar from '@/components/NavBar';
export default {
  name: 'groupSMS',
  components: { NavBar },
  computed: {
    ...mapState(['note']),
    Time: function() {
      return util.formatDate(this.sendingTime, 'yyyy-MM-dd hh');
    }
  },
  data() {
    return {
      member_num:0,// 选中的会员数量
      SMScontent: {}, //短信参数
      group_name: '', //发送群体名称
      checked: false, //控制定时发送
      show: false, //控制时间选择器
      sendingTime: new Date(), //选择的定时发送时间
      minDate: new Date(), //最小可选择时间
      currentDate: new Date() //当前时间选择器绑定的时间
    };
  },
  created() {
    let { id, sms_content, group_name, member_num } = this.$route.query;
    // 初始化-判断是否为再次发送
    if (id) {
      //再次发送
      this.SMScontent.template = sms_content
      this.member_num=member_num
      this.group_name = group_name;
    } else {
      table.memberNum(this.note.memberScreening).then(res=>{this.member_num=res.data.member_num})
      //初次发送
      this.SMScontent = this.note.SMScontent;
    }
  },
  methods: {
    // 群发
    send() {
      let { id, template_id, group_name } = this.$route.query;
      // 初始化-判断是否为再次发送
      if (id) {
        //再次发送
        if(this.checked){
          let data={}
          data.group_id=id
          data.sms_content=this.SMScontent.template;
          data.crontab_date = util.formatDate(this.sendingTime, 'yyyy-MM-dd');
          data.crontab_hour = parseInt(util.formatDate(this.sendingTime, 'hh'));
          table.crontabAdd(data).then(response => {
            if (response.data.result) {
              this.$router.push('sendSuccessfully')
            }
          });
        }else{
          let param = {}
          param.group_id=id;
          param.sms_content = this.SMScontent.template;
          table.currSend(param).then(response=>{
            if(response.data.result){
              this.$router.push('sendSuccessfully')
            }
          })
        }

      } else {
        //初次发送
        let param = this.note.memberScreening;
        param.group_name = this.group_name;
        param.sms_content = this.SMScontent.template;
        table.memberGroupAdd(param).then(response => {
          if(response.data.result){
            if(this.checked){
              let data={}
              data.group_id=response.data.id
              data.sms_content=this.SMScontent.template;
              data.crontab_date = util.formatDate(this.sendingTime, 'yyyy-MM-dd');
              data.crontab_hour = parseInt(util.formatDate(this.sendingTime, 'hh'));
              table.crontabAdd(data).then(response => {
                if (response.data.result) {
                  this.$router.push('sendSuccessfully')
                }
              });
            }else{
              let param = this.note.memberScreening;
              param.group_id=response.data.id;
              param.sms_content = this.SMScontent.template;
              table.currSend(param).then(response=>{
                if(response.data.result){
                  this.$router.push('sendSuccessfully')
                }
              })
            }
          }
        })
      }
    },
    // 调用手机电话功能
    callPhone(phoneNumber) {
      location.href = 'tel:' + phoneNumber;
    },
    confirm(value) {
      this.show = false;
      this.sendingTime = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.groupSMS-wrap {
  .van-cell::after {
    border-bottom: none;
  }
  .content {
    min-height: calc(100vh - 112px);
    padding: 0 30px;
    margin-top: 20px;
    background: #fff;
    .van-field {
      line-height: 70px;
      padding: 0 23px;
      background: #ffffff;
      border: 2px solid #e9e9e9;
      border-radius: 10px;
      /deep/.van-icon {
        display: flex;
        align-items: center;
        img {
          width: 33px;
          height: 31px;
        }
      }
    }
    h2 {
      font-size: 26px;
      font-weight: bold;
      color: #333333;
    }
    p {
      margin-top: 27px;
      font-weight: 500;
      font-size: 24px;
      color: #999999;
    }
    h1 {
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #e9e9e9;
      > div {
        font-size: 26px;
        font-weight: bold;
        color: #333;
        span {
          color: #ff6600;
        }
      }
      button {
        color: #d05f00 !important;
        font-size: 22px;
        font-weight: 500;
        height: 42px;
        padding: 0 25px;
      }
    }
    .line1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 90px;
      div {
        font-size: 26px;
        font-weight: 500;
        color: #333;
        span {
          color: #2c6bf8;
        }
      }
    }
    .line2 {
      margin-top: 50px;
      .van-field {
        padding-top: 20px;
        font-size: 26px;
        line-height:30px;
      }
      h2 {
        margin-bottom: 27px;
      }
      /deep/.van-field__word-limit {
        color: #2c6bf8;
      }
    }
    .line3 {
      .switch {
        display: flex;
        justify-content: space-between;
        height: 100px;
        align-items: center;
      }
    }
    > button {
      color: #fff !important;
      background: #2c6bf8;
      font-size: 30px;
      font-weight: 500;
      height: 80px;
      width: 100%;
      margin: 70px 0;
    }
  }
}
</style>
